<template>
  <div class="d-flex">
    <div class="d-flex align-center my-1 mx-1">
      <v-icon size="62" v-if="usedPercent == 'Infinity' || isNaN(usedPercent)">
        ${{ title.toLowerCase().replace(" ", "") }}Icon
      </v-icon>
      <progress-speed-chart
        :centerClass="'p-3'"
        :size="62"
        :width="6"
        :color="color"
        :value="`${usedPercent}%`"
        v-else
      />
    </div>
    <div class="d-flex flex-column justify-center ml-4">
      <div class="p-2 gray--text text--darken-1">
        <b>
          {{ title }}
        </b>
      </div>

      <div class="mt-1 p-3 font-weight-light gray--text text--darken-1">
        {{ $t('heading.hosting.summary.planCard.space.used') }}:
        <b class="font-weight-bold">{{ valueText }}</b>
        {{ usedPercentText }}
      </div>
      <div class="p-3 font-weight-light gray--text text--darken-1">
        {{ $t('heading.hosting.summary.planCard.space.available') }}:
        <v-icon v-if="availableText === '∞'" size="20" color="gray darken-1">
          $infiniteIcon
        </v-icon>
        <b v-else class="font-weight-bold">{{ availableText }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressSpeedChart from "../charts/ProgressSpeedChart.vue";

export default {
  components: {
    ProgressSpeedChart,
  },
  data: function () {
    return {};
  },
  props: {
    progressIcon: String,
    title: {
      type: String,
      required: true,
    },
    value: [Number, String],
    valueText: [Number, String],
    available: [Number, String],
    availableText: String,
  },
  computed: {
    usedPercent() {
      return Math.ceil((this.value / this.available) * 100);
    },
    usedPercentText() {
      if (this.available !== null) {
        return `(${this.usedPercent}%)`;
      }
      return "";
    },
    color() {
      return this.value == "?" || this.available === null
        ? "#E5E5F0"
        : this.usedPercent <= 50
        ? "success"
        : this.usedPercent <= 80
        ? "warning"
        : "error";
    },
  },
};
</script>

<style scoped lang="scss">
.v-skeleton-loader::v-deep {
  .v-skeleton-loader {
    &__avatar {
      height: 96px;
      width: 96px;
    }
  }
}
</style>