<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class="title-row"
        :class="`${
          $vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
        }`"
      >
        <v-col class="d-flex align-center title-column">
          <h4 class="font-weight-light">{{ $t('heading.hosting.summary.title') }}</h4>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-3 px-0">
      <v-row>
        <v-col cols="12" md="6">
          <hosting-nameservers-card
            :serverAccount="serverAccount"
            :loading="loading"
            :nameservers="nameservers"
          />
          <hosting-instances-card
            :serverAccount="serverAccount"
            :loading="loading"
          />
        </v-col>

        <v-col cols="12" md="6">
          <hosting-summary-card-skeleton v-if="loading" />
          <hosting-summary-card
            v-else
            :usageData="planUsage"
            :serverAccount="serverAccount"
            :availableSpace="[
              {
                title: $t('heading.hosting.summary.planCard.usage.disk'),
                available: usage.disk_usage.maximum,
                availableText: formatBytes(usage.disk_usage.maximum),
                value: usage.disk_usage.usage,
                valueText: formatBytes(usage.disk_usage.usage),
              },
              {
                title: $t('heading.hosting.summary.planCard.usage.email'),
                available: usage.email_accounts.maximum,
                availableText: formatAmount(usage.email_accounts.maximum),
                value: usage.email_accounts.usage,
                valueText: formatAmount(usage.email_accounts.usage),
              },
            ]"
          >
            <template v-slot:action>
              <v-btn
                v-if="serverAccount.plan_upgrade_link"
                :href="
                  serverAccount.plan_upgrade_link +
                  '?paupgradeserviceid=' +
                  serverAccount.service_id
                "
                target="_blank"
                x-small
                text
                color="info"
              >
                <span class="font-weight-600">
                  <v-icon size="16" class="mr-1">$restorebackup</v-icon>
                  {{ $t('button.upgrade') }}
                </span>
              </v-btn>
            </template>
          </hosting-summary-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HostingNameserversCard from "../../components/cards/HostingNameserversCard.vue";
import HostingInstancesCard from "../../components/cards/HostingInstancesCard.vue";
import HostingSummaryCard from "../../components/cards/HostingSummaryCard.vue";
import HostingSummaryCardSkeleton from "../../components/cards/HostingSummaryCardSkeleton.vue";

import Api from "../../apis/Api";
import Helper from "../../apis/Helper";

export default {
  components: {
    HostingSummaryCard,
    HostingSummaryCardSkeleton,
    HostingNameserversCard,
    HostingInstancesCard,
  },
  props: {
    serverAccount: Object,
  },
  watch: {
    serverAccount: function () {
      this.reloadData();
    },
  },
  created() {
    if (this.serverAccount) {
      this.reloadData();
    }
  },
  data: function () {
    return {
      loading: true,
      usage: {},
      nameservers: [],
    };
  },
  computed: {
    planUsage() {
      if (!Object.keys(this.usage).length) {
        return [];
      }
      return [
        {
          name: this.$t('heading.hosting.summary.planCard.usage.bandwidth'),
          value: parseInt(this.usage.bandwidth.usage),
          valueText: this.formatBytes(this.usage.bandwidth.usage),
          maxValue: parseInt(this.usage.bandwidth.maximum),
          maxValueText: this.formatBytes(this.usage.bandwidth.maximum),
        },
        {
          name: this.$t('heading.hosting.summary.planCard.usage.ftp'),
          value: parseInt(this.usage.ftp_accounts.usage),
          valueText: this.usage.ftp_accounts.usage,
          maxValue: parseInt(this.usage.ftp_accounts.maximum),
          maxValueText: this.formatAmount(this.usage.ftp_accounts.maximum),
          url: "/hosting/ftp",
          privilege: 'hosting.manage_ftp'
        },
        {
          name: this.$t('heading.hosting.summary.planCard.usage.mysql'),
          value: parseInt(this.usage.mysql_databases.usage),
          valueText: this.usage.mysql_databases.usage,
          maxValue: parseInt(this.usage.mysql_databases.maximum),
          maxValueText: this.formatAmount(this.usage.mysql_databases.maximum),
          url: "/hosting/databases",
          privilege: 'hosting.manage_mysql'
        },
        {
          name: this.$t('heading.hosting.summary.planCard.usage.subdomains'),
          value: parseInt(this.usage.subdomains.usage),
          valueText: this.usage.subdomains.usage,
          maxValue: parseInt(this.usage.subdomains.maximum),
          maxValueText: this.formatAmount(this.usage.subdomains.maximum),
          url: "/hosting/domains",
          privilege: 'hosting.manage_domains'
        },
        {
          name: this.$t('heading.hosting.summary.planCard.usage.email'),
          value: parseInt(this.usage.email_accounts.usage),
          valueText: this.usage.email_accounts.usage,
          maxValue: parseInt(this.usage.email_accounts.maximum),
          maxValueText: this.formatAmount(this.usage.email_accounts.maximum),
          url: "/hosting/email-addresses",
          privilege: 'hosting.manage_email'
        },
        {
          name: this.$t('heading.hosting.summary.planCard.usage.addonDomains'),
          value: parseInt(this.usage.addon_domains.usage),
          valueText: this.usage.addon_domains.usage,
          maxValue: parseInt(this.usage.addon_domains.maximum),
          maxValueText: this.formatAmount(this.usage.addon_domains.maximum),
          url: "/hosting/domains",
          privilege: 'hosting.manage_domains'
        },
      ];
    },
  },
  methods: {
    reloadData() {
      this.nameservers = this.serverAccount.nameservers;
      this.loading = true;
      Api.cached(0)
        .get(`/server-accounts/${this.serverAccount.id}/usage`)
        .then((response) => {
          this.usage = response.data.data;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatBytes(bytes) {
      if (bytes === null) {
        return "∞";
      }
      return Helper.formatBytes(bytes);
    },
    formatAmount(amount) {
      if (amount === null) {
        return "∞";
      }
      return amount;
    },
  },
};
</script>

<style scoped lang="scss">
.title-row {
  @media (min-width: 1401px) {
    height: 70px;
  }
}
.v-card {
  .v-card__text {
    -webkit-line-clamp: unset;
  }
}
</style>
